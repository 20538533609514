export default function ContactsData() {
    return Data();
}

export const Data = () => {
    return {
        fbLink: "https://www.facebook.com/viktoria.adkozalova",
        fbTitle: "Viktoria.Adkozalova",
        instLink: "https://www.instagram.com/viktoria_adkozalova",
        instTitle: "@viktoria_adkozalova",
        youtubeLink: "https://www.youtube.com/channel/UCVvX_5I145CX4TKzWfTZJvA",
        youtubeTitle: "Youtube",
        phoneLink: "tel:+380507179383",
        phoneTitle: "+380 50 717 9383",
        emailLink: "mailto:adkozalova.v@gmail.com",
        emailTitle: "adkozalova.v@gmail.com",
    }
}